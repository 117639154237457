html, body, #root {
  height: 100vh;
  background-color: #fcfcfc;
  font-family: "Work Sans", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

svg {
  width: 100%;
  height: 100%;
}

#WEB3_CONNECT_MODAL_ID > div {
  z-index: 1301;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}